import { css } from '@emotion/react';
import {
  fontSize,
  lineHeight,
  spacing,
} from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  max-width: 640px;
  display: grid;
  grid-gap: ${spacing(24)};

  @media ${breakpoints.medium} {
    grid-gap: ${spacing(32)};
    grid-template-columns: 80px auto;
    align-items: flex-start;
  }
`;

export const wrapper = css`
  display: flex;
  padding-top: ${spacing(80)};
  padding-bottom: ${spacing(80)};
  justify-content: center;

  @media ${breakpoints.medium} {
    padding-top: ${spacing(160)};
    padding-bottom: ${spacing(160)};
  }
`;

export const contentWrapper = css`
  padding: ${spacing(32)} 0;

  @media ${breakpoints.medium} {
    padding: 0;
  }
`;

export const icon = css`
  padding: ${spacing(17)};
  border: 2px solid hsl(var(--color-border-9));
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  border-radius: 100%;

  @media ${breakpoints.medium} {
    padding: ${spacing(22)};
    width: 32px;
    height: 32px;
  }
`;

export const content = css`
  font-size: ${fontSize('base', 'medium')};
  line-height: ${lineHeight('base', 'medium')};
  color: hsl(var(--color-grayscale-6));

  a {
    transition: color 0.2s;
    color: hsl(var(--color-text-1));
    text-decoration: underline;

    &:hover,
    &:active {
      color: hsl(var(--color-text-3));
    }
  }
`;

export const gridSpan = css`
  grid-column: -1/1;
  justify-self: center;
`;

export const sideWrapper = css`
  padding: ${spacing(80)} ${spacing(32)} !important;
`;
